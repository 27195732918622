import React, { useState, useEffect } from "react";
import { Typography, Button } from "@material-tailwind/react";
import { Link, useLocation } from "react-router-dom";
import PremaritalCounseling from "../../../components/popup/PremaritalCounseling";
const questions = [
  "答應對方的事，我們相信彼此都會盡力實現",
  "我們可以分享彼此的喜怒哀樂",
  "我們互相尊重對方的興趣和想法",
  "我們互相信賴對方",
  "我們互相關心",
  "我可以包容對方的缺點",
  "我們可以體諒彼此的難處",
  "我的關係/婚姻很幸福",
  "我們可以共同面對困難，一起克服",
  "我與伴侶一起時，能做自己",
];

const reverseQuestion = [
  "我覺得我們溝通常常無法坦誠",
  "我們對於彼此斤斤計較",
  "我們常常吵架",
  "我們沒有共同話題",
  "我不滿足現時的性生活",
  "如果可以重新選擇，我不會選擇一起/進入婚姻",
  "我對我們的談話常常提不起興趣",
  "在很多方面，我們彼此看不慣對方",
  "我曾認真的想過要分手/離婚",
  "我不能信任對方",
];

const allQuestions = [...questions, ...reverseQuestion];
const mixedQuestions = [...new Set(allQuestions)].sort(
  () => Math.random() - 0.5
);

const PAForm = () => {
  // console.log(mixedQuestions);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [totalScore, setTotalScore] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showHelpComponent, setShowHelpComponent] = useState(false);

  const handleShowHelp = () => {
    setShowHelpComponent(true);
  };
  const handleAnswer = (value) => {
    // console.log(mixedQuestions[currentQuestionIndex]);
    const isReverse = reverseQuestion.includes(
      mixedQuestions[currentQuestionIndex]
    ); // Check if the current question is from reverseQuestion
    const adjustedValue = isReverse ? 6 - value : value; // Reverse scoring for reverse questions

    if (currentQuestionIndex < mixedQuestions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setTotalScore(totalScore + adjustedValue);
    } else {
      setTotalScore(totalScore + adjustedValue);
      setIsSubmitted(true);
      console.log(
        "Form submitted with total score:",
        totalScore + adjustedValue
      );
    }

    console.log(
      `Question ${
        currentQuestionIndex + 1
      } answer: ${adjustedValue} Total Score: ${totalScore + adjustedValue}`
    );
  };

  const getResultMessage = (score) => {
    if (score <= 25) {
      return {
        result: "你們的關係就像已腐爛不堪的蘋果",
        advice:
          "現在的你應該是身受關係困擾，兩人間的信任、尊重、支持不足，讓你感到相當挫敗或氣憤。你可能在關係中不是被要求過多，又或是不被重視的感受折磨，而感到煎熬。每次提出問題，都會輕易被忽視、不被了解，或每次被控訴，都沒有機會被聆聽。",
        solution:
          "我們建議你停止停留在關係中繼續委屈，不要再纏繞於這種內訌的關係，鼓勵你嘗試走出這種痛苦。如果你很想離開痛苦邊緣，讓自己找尋理想關係的平衡狀態，需要專業的輔導員協助整理及提供建議，歡迎你/你們填寫資料嘗試輔導服務。",
        image: "/images/self-test/3.png", // Added image path
        contact: true,
      };
    } else if (score >= 26 && score <= 50) {
      return {
        result: "你們的關係就像表面正常，但內裏開始腐爛的蘋果",
        advice:
          "你們的關係表面上沒有什麼大問題，大家都沒有有勇氣坦白提出關係中深層的感受、困擾。每次遇到衝突時，大家都選擇避而不談，甚至裝作沒有問題。想坦誠處理時，卻因為沒有足夠勇氣，最後不了了之。於你們的關係中，溝通可能已經出現裂痕，甚至步伐出現問題。",
        solution:
          "在關係裂痕開展初期覺醒，嘗試修補，你們的關係有機會慢慢步向正確的路軌。雖然改變會帶來危機，甚至面對的時候也會感覺有點痛苦，但迎來的將會是關係的轉機。倘若你正徬徨不知如何是好，但很想改變，歡迎你/你們填寫資料嘗試輔導服務。",
        image: "/images/self-test/2.png", // Added image path
        contact: true,
      };
    } else if (score >= 51 && score <= 75) {
      return {
        result: "你們的關係就像沒有味道的蘋果",
        advice:
          "平穩是你們關係的代表詞，在關係中的你，大多時候是感到滿意的。當然，有時也許會感覺到小小的不完美，但這就是一段關係的正常步伐。",
        solution:
          "如果你仍然會糾結在關係中小小不完美的部份，可能代表著這段關係仍有著創造、成長的空間。如果你有猶豫，想整理關係中可創造的元素，有所成長，歡迎你/你們填寫資料嘗試輔導服務。",
        image: "/images/self-test/1.png", // Added image path
        contact: true,
      };
    } else {
      return {
        result: "你們的關係就像剛剛成熟的蘋果",
        advice:
          "經營一段關係，需要經驗不少風雨累積溝通模式及反思整理自己，你們目前有足夠的情感信念面對過往所面臨的人生階段。",
        solution:
          "這些經驗累積下來的尊重、信任、包容、支持與溝通的智慧，將是往後邁向不同階段的重要資產，也讓你及伴侶一同成為更棒的人，只要繼續滋養維持，定能面對不同阻礙，建立適合你們的相處模式。",
        image: "/images/self-test/1.png", // Added image path
        contact: false,
      };
    }
  };

  return (
    <div className="flex flex-col gap-4 md:mt-12 ">
      {!isSubmitted ? (
        <form className="">
          <Typography className="!text-gray-600 text-[20px] font-reborn self-test-question">
            {currentQuestionIndex + 1}. {mixedQuestions[currentQuestionIndex]}
          </Typography>
          <div className="flex flex-col gap-2 mt-3 self-test-answer">
            <Button
              variant="outlined"
              size="lg"
              className="border p-2 rounded font-reborn"
              onClick={() => handleAnswer(1)}
            >
              非常不同意
            </Button>

            <Button
              variant="outlined"
              size="lg"
              className="border p-2 rounded font-reborn"
              onClick={() => handleAnswer(2)}
            >
              不同意
            </Button>
            <Button
              variant="outlined"
              size="lg"
              className="border p-2 rounded font-reborn "
              onClick={() => handleAnswer(3)}
            >
              普通
            </Button>
            <Button
              variant="outlined"
              size="lg"
              className="border p-2 rounded font-reborn"
              onClick={() => handleAnswer(4)}
            >
              同意
            </Button>
            <Button
              variant="outlined"
              size="lg"
              className="border p-2 rounded font-reborn"
              onClick={() => handleAnswer(5)}
            >
              非常同意
            </Button>
            <Typography className="mt-3 font-reborn !text-gray-600 md:max-w-sm flex-shrink-0 mx-auto text-center">
              {currentQuestionIndex + 1} / {mixedQuestions.length}
            </Typography>
          </div>
        </form>
      ) : (
        <>
          <Typography className="text-[20px] text-gray-600 font-reborn">
            <span className="font-bold">結果分析</span>
          </Typography>

          <div className="flex items-center">
            <Typography className="text-[18px] text-gray-600 font-reborn flex items-center">
              {getResultMessage(totalScore).result}
              {getResultMessage(totalScore).image && (
                <img
                  src={getResultMessage(totalScore).image}
                  alt="Result Image"
                  className="h-8 w-8 ml-1 mb-1"
                />
              )}
            </Typography>
          </div>

          <Typography className="text-[18px] text-gray-600 font-reborn">
            {getResultMessage(totalScore).advice}
          </Typography>
          <Typography className="text-[20px] text-gray-600 font-reborn">
            <span className="font-bold">建議方向</span>
          </Typography>
          <Typography className="text-[18px] text-gray-600 font-reborn">
            {getResultMessage(totalScore).solution}
          </Typography>

          {getResultMessage(totalScore).contact && (
            <div className="flex flex-col items-start]">
              <Typography className="text-[18px] text-gray-600 font-reborn">
                請點擊
                <span className="click-here " onClick={handleShowHelp}>
                  這裏
                </span>
              </Typography>
              {showHelpComponent && (
                <PremaritalCounseling
                  open={showHelpComponent}
                  close={() => setShowHelpComponent(false)}
                  setOpen={setShowHelpComponent}
                />
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PAForm;
